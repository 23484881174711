import React from 'react';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';

import {
  MobileHeaderLayoutB,
  DesktopHeaderLayoutA,
  SectionUnderHeader,
  SliderWithClickableBlocks,
  TechStackWithCategoriesAboveLogos,
  ContactButtonImageSection,
  TextImageSection,
  TextButtonImageSection,
} from '@commons/index';

import {
  sliderWithClickableBlocksProps,
  headerProps,
  sectionUnderHeaderProps,
  SEOProps,
  textButtonImageSectionProps,
  textImageSectionProps,
  networkSolutionsProps,
  softwareDevelopmentProps,
  cloudsPlatformsProps,
  devOpsProps,
  dataEngineeringProps,
  dataScienceProps,
  contactButtonImageSectionProps,
} from '@pages-impl/technology-stack/payload.jsx';

export default function TechnologyStack(props) {
  return (
    <Layout {...props}>
      <SEO {...SEOProps} />
      <MobileHeaderLayoutB {...headerProps} />
      <DesktopHeaderLayoutA {...headerProps} />
      <SectionUnderHeader {...sectionUnderHeaderProps} />
      <TextImageSection {...textImageSectionProps} />
      <TechStackWithCategoriesAboveLogos {...networkSolutionsProps} />
      <TechStackWithCategoriesAboveLogos {...softwareDevelopmentProps} />
      <TechStackWithCategoriesAboveLogos {...cloudsPlatformsProps} />
      <TechStackWithCategoriesAboveLogos {...devOpsProps} />
      <TechStackWithCategoriesAboveLogos {...dataEngineeringProps} />
      <TechStackWithCategoriesAboveLogos {...dataScienceProps} />
      <ContactButtonImageSection {...contactButtonImageSectionProps} />
      <SliderWithClickableBlocks {...sliderWithClickableBlocksProps} />
      <TextButtonImageSection {...textButtonImageSectionProps} />
    </Layout>
  );
}
