// extracted by mini-css-extract-plugin
export var contactButtonImageSection = "payload-module--contact-button-image-section--5e41e";
export var contactButtonImageSectionCustomContent = "payload-module--contact-button-image-section-custom-content--c0c63";
export var contactButtonImageSectionImageContainer = "payload-module--contact-button-image-section-image-container--f4ad1";
export var contactButtonImageSectionInnerWrapper = "payload-module--contact-button-image-section-inner-wrapper--8850e";
export var dataInnerWrapper = "payload-module--data-inner-wrapper--ad2b1";
export var greySection = "payload-module--grey-section--5bf77";
export var newLineWithMargin = "payload-module--new-line-with-margin--4956c";
export var sliderWithClickableBlocksInnerWrapper = "payload-module--slider-with-clickable-blocks-inner-wrapper--ad641";
export var sliderWithClickableBlocksSection = "payload-module--slider-with-clickable-blocks-section--a3cc3";
export var titleBorderWidth = "payload-module--title-border-width--69c5f";
export var underHeaderImageContainer = "payload-module--under-header-image-container--bc79b";
export var underHeaderInnerWrapper = "payload-module--under-header-inner-wrapper--6b9bb";