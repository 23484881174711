import React from 'react';
import { paths } from '@src/constants/pathsEnum';
import featuredImage from './images/technology-stack-feature-image.jpg';
import weWorkWith from './images/we-work-with.svg';
import letsTalkImage from './images/lets-talk.svg';
import headerBackgroundImage from './images/header-background-image.svg';
import imageOnRight from '@images/technology-stack/meet-our-team.svg';
import { ButtonTypeEnum } from '@commons/button';

import {
  p4,
  sonic,
  openVSwitch,
  tungstenFabric,
  cisco,
  juniperNetworks,
  paloAltoNetworks,
  aristaNetworks,
  huawei,
  f5,
  checkPoint,
  nokiaNetworks,
  hotjar,
  figma,
  react,
  angular,
  vueJS,
  redux,
  less,
  javaScript,
  typeScript,
  django,
  go,
  cPlusPlus,
  python,
  kafka,
  java,
  kotlin,
  nodeJS,
  miro,
  sketch,
  axure,
  zeplin,
  rust,
  c,
  dpdk,
  cypress,
  selenium,
  robotFramework,
  pytest,
  tRex,
  googleCloud,
  aws,
  azure,
  ibmCloud,
  redHat,
  openStack,
  ubuntu,
  debian,
  openShift,
  kubernetes,
  docker,
  envoy,
  istio,
  kvm,
  xen,
  etcd,
  mariaDB,
  postgreSQL,
  mongoDB,
  ansible,
  terraform,
  puppet,
  saltStack,
  circleCI,
  travisCI,
  bamboo,
  jenkins,
  zuul,
  spinnaker,
  gitLab,
  vault,
  vagrant,
  helm,
  juju,
  groovy,
  git,
  fluentd,
  prometheus,
  grafana,
  elkStack,
  snowflake,
  apacheSpark,
  cassandra,
  hadoop,
  apacheMXNet,
  tensorFlow,
  adobeCreativeCloud,
  vpp,
  dell,
  almaLinux,
  proxmox,
  vmware,
  chef,
  argo,
  gitHubActions,
  apacheHive,
  elasticsearch,
  kibana,
  amazonRedshift,
  sas,
  rabbitMQ,
  amazonKinesis,
  amazonSQS,
  redis,
  timescale,
  mySQL,
  microsoftSQLServer,
  oracle,
  apacheAirflow,
  statsmodels,
  darts,
  prophet,
  pandas,
  sktime,
  nixtla,
  sciPy,
  gluonTS,
  gPflow,
  hyperopt,
  keras,
  plotly,
  matplotlib,
  seaborn,
  influxDB,
  openTSDB,
  scikitLearn,
  xGBoost,
  jupyter,
} from '@images/logos-techstack';

import * as styles from './payload.module.scss';

export const SEOProps = {
  title: 'Our Tech Stack - What Technologies We Use | CodiLime',
  description:
    'At CodiLime, we help solve business problems with up-to-date and stable technologies to ensure the highest quality code. Learn about our core technologies.',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: (
    <>
      Our <br />
      Technology <br />
      Stack
    </>
  ),
  backgroundImage: headerBackgroundImage,
  backgroundImageAlt: 'Our Technology Stack',
  titleBorderWidth: styles.titleBorderWidth,
};

export const sectionUnderHeaderProps = {
  descriptionPartOne: (
    <>
      At CodiLime, <b>we help solve the business problems</b> of companies from demanding, high-technology industries.
      We know that our dedicated software and network engineering teams need to work with the best technologies
      available to provide nothing less than <b>code excellence.</b>
      <br className={styles.newLineWithMargin} /> <b>Let us help you with your software project.</b>
    </>
  ),
  contactButtonLabel: 'Contact Us',
  classNames: {
    innerWrapper: styles.underHeaderInnerWrapper,
    imageContainer: styles.underHeaderImageContainer,
  },
};

export const sliderWithClickableBlocksProps = {
  sectionProps: {
    title: 'How do we work with our tech-savvy partners?',
    subtitle: (
      <>
        Whether you need experts in a particular technology or are looking for a partner who will deliver a complete
        software product to your doorstep, we are here to help you. Check out how we can work together.
      </>
    ),
    id: 'how-we-work',
  },
  classNames: {
    section: styles.sliderWithClickableBlocksSection,
    innerWrapper: styles.sliderWithClickableBlocksInnerWrapper,
  },
};

export const textImageSectionProps = {
  sectionProps: {
    title: 'The technology stack we work with',
    subtitle: (
      <>
        Every project requires a dedicated approach and a <b>carefully selected tech stack</b> to ensure that its scope
        will be delivered on time and the code will be spotless. Check out what technologies our engineers and
        developers work with.
      </>
    ),
    id: 'work-with',
  },
  image: weWorkWith,
  imageAlt: 'The technology stack we work with',
};

export const networkSolutionsProps = {
  title: (
    <>
      Network <br />
      solutions
    </>
  ),
  subtitle: (
    <>
      Networks are in our DNA. We have been designing and building resilient and secure network solutions for global
      telcos since 2011.
    </>
  ),
  id: 'network-solutions',
  rowsWithBlocks: [
    {
      categoryTitle: <>Open source networking</>,
      blocks: [p4, sonic, vpp, openVSwitch, tungstenFabric],
    },
    {
      categoryTitle: <>Vendor solutions</>,
      blocks: [cisco, paloAltoNetworks, juniperNetworks, dell, aristaNetworks, huawei, f5, checkPoint, nokiaNetworks],
    },
  ],
};

export const softwareDevelopmentProps = {
  title: 'Software development',
  subtitle: (
    <>
      CodiLime&apos;s software engineering teams work with the best and future-proof technologies for front-end,
      back-end, or low-level programming-related projects.
    </>
  ),
  id: 'software-development',
  classNames: { section: styles.greySection },
  rowsWithBlocks: [
    {
      categoryTitle: <>UX Design</>,
      blocks: [hotjar, figma, adobeCreativeCloud, miro, sketch, axure, zeplin],
    },
    {
      categoryTitle: 'Frontend',
      blocks: [react, angular, vueJS, redux, less, javaScript, typeScript, django],
    },
    {
      categoryTitle: 'Backend',
      blocks: [go, cPlusPlus, python, kafka, java, kotlin, nodeJS],
    },
    {
      categoryTitle: 'Low-level',
      blocks: [rust, c, dpdk],
    },
    {
      categoryTitle: 'Testing',
      blocks: [cypress, selenium, robotFramework, pytest, tRex],
    },
  ],
};

export const cloudsPlatformsProps = {
  title: (
    <>
      Clouds/OS/
      <br />
      platforms
    </>
  ),
  subtitle: (
    <>
      When your project needs cloud expertise, the CodiLime teams are here to build and integrate your cloud solutions
      across private, public and hybrid cloud environments.
    </>
  ),
  id: 'clouds-os-platforms',
  rowsWithBlocks: [
    {
      categoryTitle: 'Public cloud',
      blocks: [googleCloud, aws, azure, ibmCloud],
    },
    {
      categoryTitle: 'Private cloud',
      blocks: [openStack],
    },
    {
      categoryTitle: 'Operating systems',
      blocks: [ubuntu, redHat, almaLinux, debian],
    },
    {
      categoryTitle: 'Cloud-native services',
      blocks: [openShift, kubernetes, docker, envoy, istio],
    },
    {
      categoryTitle: 'Virtualization',
      blocks: [kvm, xen, proxmox, vmware],
    },
    {
      categoryTitle: 'Storage/database',
      blocks: [etcd, mariaDB, postgreSQL, mongoDB],
    },
  ],
};

export const devOpsProps = {
  title: 'DevOps',
  subtitle: (
    <>
      The DevOps culture and approach are strong in CodiLime&apos;s teams. We deliver value faster, more reliably, and
      cost-effectively.
    </>
  ),
  id: 'devOps',
  classNames: { section: styles.greySection },
  rowsWithBlocks: [
    {
      categoryTitle: 'Automation',
      blocks: [ansible, puppet, saltStack, chef],
    },
    {
      categoryTitle: 'CI/CD',
      blocks: [circleCI, travisCI, bamboo, jenkins, zuul, spinnaker, gitLab, argo, gitHubActions],
    },
    {
      categoryTitle: 'IaC',
      blocks: [vault, terraform, vagrant, helm, juju, groovy, git],
    },
    {
      categoryTitle: 'Monitoring',
      blocks: [fluentd, prometheus, grafana, elkStack],
    },
  ],
};

export const dataEngineeringProps = {
  title: 'Data engineering',
  subtitle:
    'Data engineering enables the processing, storing, and analyzing of extensive volumes of data generated by users, software, and machines.',
  id: 'data-engineering',
  classNames: { innerWrapper: styles.dataInnerWrapper },
  rowsWithBlocks: [
    {
      categoryTitle: 'Cloud-native data processing',
      blocks: [googleCloud, aws, azure, ibmCloud],
    },
    {
      categoryTitle: 'Apache data stack and their managed, cloud-based versions',
      blocks: [hadoop, apacheSpark, apacheHive],
    },
    {
      categoryTitle: 'Elastic Stack',
      blocks: [elasticsearch, kibana],
    },
    {
      categoryTitle: 'Data warehousing tools',
      blocks: [amazonRedshift, snowflake, sas],
    },
    {
      categoryTitle: 'Queuing solutions',
      blocks: [kafka, rabbitMQ, amazonKinesis, amazonSQS],
    },
    {
      categoryTitle: 'Caching mechanisms',
      blocks: [redis],
    },
    {
      categoryTitle: 'NoSQL solutions',
      blocks: [mongoDB, cassandra, etcd, timescale],
    },
    {
      categoryTitle: 'Relational databases',
      blocks: [postgreSQL, mySQL, mariaDB, microsoftSQLServer],
    },
    {
      categoryTitle: 'Data management tools',
      blocks: [oracle, apacheAirflow],
    },
  ],
};

export const dataScienceProps = {
  title: 'Data science',
  subtitle:
    'Data science involves leveraging AI, machine learning, deep learning, and various algorithms to derive valuable business insights from large quantities of data.',
  id: 'data-science',
  classNames: { section: styles.greySection },
  rowsWithBlocks: [
    {
      categoryTitle: 'Experimentation environment',
      blocks: [jupyter, python],
    },
    {
      categoryTitle: 'Data analysis and machine learning models',
      blocks: [
        scikitLearn,
        xGBoost,
        statsmodels,
        darts,
        prophet,
        pandas,
        sktime,
        nixtla,
        sciPy,
        gluonTS,
        gPflow,
        hyperopt,
      ],
    },
    {
      categoryTitle: 'Deep Learning',
      blocks: [pytest, keras, tensorFlow, apacheMXNet],
    },
    {
      categoryTitle: 'Data Visualisation',
      blocks: [plotly, matplotlib, seaborn, grafana],
    },
    {
      categoryTitle: 'Databases',
      blocks: [influxDB, openTSDB, postgreSQL],
    },
  ],
};

export const contactButtonImageSectionProps = {
  sectionProps: {
    classNames: {
      section: styles.contactButtonImageSection,
      innerWrapper: styles.contactButtonImageSectionInnerWrapper,
    },
  },
  title: 'Do you see a technology you need?',
  buttonTitle: 'Contact Us',
  typeOfButton: ButtonTypeEnum.LIME,
  image: letsTalkImage,
  imageAlt: 'Get in touch and learn more about our technologies',
  isH2: true,
  classNames: {
    customContent: styles.contactButtonImageSectionCustomContent,
    imageContainer: styles.contactButtonImageSectionImageContainer,
  },
};

export const textButtonImageSectionProps = {
  title: 'Need more? Meet our R&D team!',
  description:
    'In the progressive technology environment, you need coders and thinkers to solve the most complex issues. At CodiLime we have a Research and Development team ready to jump into deep waters!',
  id: 'meet-our-team',
  imageData: {
    src: imageOnRight,
    alt: 'Learn more about the R&D team',
  },
  buttonData: {
    href: paths.SERVICES.R_D,
    label: 'Explore our R&D',
  },
};
